import React, { useState, useEffect, useCallback } from 'react';
import { identity, getSingleProfile, submitPost } from 'deso-protocol';
import EmojiPicker from 'emoji-picker-react';
import './DesoPostApp.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSignInAlt,
  faSignOutAlt,
  faMoon,
  faSun,
  faUpload,
  faDownload,
  faSmile,
  faCalendarAlt,
} from '@fortawesome/free-solid-svg-icons';

const DesoPostApp = () => {
  const [postsList, setPostsList] = useState([]);
  const [currentPost, setCurrentPost] = useState('');
  const [scheduledPosts, setScheduledPosts] = useState([]);
  const [scheduleDate, setScheduleDate] = useState('');
  const [scheduleTime, setScheduleTime] = useState('');
  const [isPosting, setIsPosting] = useState(false);
  const [postStatus, setPostStatus] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [publicKey, setPublicKey] = useState('');
  const [username, setUsername] = useState('');
  const [isDarkMode, setIsDarkMode] = useState(true);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  useEffect(() => {
    const savedPosts = localStorage.getItem('multiPlatformPosts');
    const savedScheduledPosts = localStorage.getItem('scheduledPosts');
    if (savedPosts) setPostsList(JSON.parse(savedPosts));
    if (savedScheduledPosts) setScheduledPosts(JSON.parse(savedScheduledPosts));
    checkLoginStatus();
  }, []);

  useEffect(() => {
    localStorage.setItem('multiPlatformPosts', JSON.stringify(postsList));
  }, [postsList]);

  useEffect(() => {
    localStorage.setItem('scheduledPosts', JSON.stringify(scheduledPosts));
  }, [scheduledPosts]);

  useEffect(() => {
    const intervalId = setInterval(checkScheduledPosts, 60000); // Check every minute
    return () => clearInterval(intervalId);
  }, [scheduledPosts]);

  const checkLoginStatus = async () => {
    try {
      const userKey = identity.getUserKey();
      if (userKey) {
        setIsLoggedIn(true);
        setPublicKey(userKey);
        const profileResponse = await getSingleProfile({
          PublicKeyBase58Check: userKey,
        });
        if (profileResponse && profileResponse.Profile) {
          setUsername(profileResponse.Profile.Username);
        }
      }
    } catch (error) {
      console.error('Error checking login status:', error);
    }
  };

  const handleLogin = async () => {
    try {
      const response = await identity.login();
      if (response && response.publicKeyAdded) {
        setIsLoggedIn(true);
        setPublicKey(response.publicKeyAdded);
        setPostStatus('Logged in successfully!');
        const profileResponse = await getSingleProfile({
          PublicKeyBase58Check: response.publicKeyAdded,
        });
        if (profileResponse && profileResponse.Profile) {
          setUsername(profileResponse.Profile.Username);
        }
      } else {
        setPostStatus('Login failed. Please try again.');
      }
    } catch (error) {
      console.error('Login error:', error);
      setPostStatus('Login failed. Please try again.');
    }
  };

  const handleLogout = async () => {
    try {
      await identity.logout();
      setIsLoggedIn(false);
      setPublicKey('');
      setUsername('');
      setPostStatus('Logged out successfully!');
    } catch (error) {
      console.error('Logout error:', error);
      setPostStatus('Logout failed. Please try again.');
    }
  };

  const handleSavePost = () => {
    if (currentPost.trim()) {
      const newPost = { id: Date.now(), content: currentPost };
      setPostsList([...postsList, newPost]);
      setCurrentPost('');
    }
  };

  const handleDesoPost = async (content = currentPost) => {
    if (!isLoggedIn || !publicKey) {
      setPostStatus('Please log in to post to DeSo.');
      return;
    }
    if (!content.trim()) {
      setPostStatus('Please enter some content to post.');
      return;
    }
    setIsPosting(true);
    setPostStatus('Posting to DeSo...');
    try {
      const response = await submitPost({
        UpdaterPublicKeyBase58Check: publicKey,
        BodyObj: { Body: content, ImageURLs: [] },
      });
      console.log('Post submitted to DeSo:', response);
      handleSavePost();
      setPostStatus('Post submitted to DeSo successfully!');
      return response;
    } catch (error) {
      console.error('Error posting to DeSo:', error);
      setPostStatus(`Failed to submit post to DeSo: ${error.message}`);
      throw error;
    } finally {
      setIsPosting(false);
    }
  };

  const handleWarpcastPost = () => {
    if (!currentPost.trim()) {
      setPostStatus('Please enter some content to post.');
      return;
    }
    const encodedPost = encodeURIComponent(currentPost);
    const warpcastUrl = `https://warpcast.com/~/compose?text=${encodedPost}`;
    window.open(warpcastUrl, '_blank');
    setPostStatus('Warpcast window opened. Please complete your post there.');
  };

  const handleSchedulePost = () => {
    if (!currentPost.trim() || !scheduleDate || !scheduleTime) {
      alert('Please enter post content and schedule time');
      return;
    }

    const scheduledTime = new Date(`${scheduleDate}T${scheduleTime}`);
    if (scheduledTime <= new Date()) {
      alert('Scheduled time must be in the future');
      return;
    }

    const newScheduledPost = {
      id: Date.now(),
      content: currentPost,
      scheduledTime: scheduledTime.toISOString(),
      published: false,
    };

    setScheduledPosts([...scheduledPosts, newScheduledPost]);
    setCurrentPost('');
    setScheduleDate('');
    setScheduleTime('');
    setPostStatus('Post scheduled successfully!');
  };

  const checkScheduledPosts = useCallback(async () => {
    const now = new Date();
    const updatedPosts = await Promise.all(scheduledPosts.map(async (post) => {
      if (new Date(post.scheduledTime) <= now && !post.published) {
        try {
          await handleDesoPost(post.content);
          return { ...post, published: true };
        } catch (error) {
          console.error('Failed to publish scheduled post:', error);
          return post;
        }
      }
      return post;
    }));
    setScheduledPosts(updatedPosts);
  }, [scheduledPosts]);

  const handleExportPosts = () => {
    const dataStr = JSON.stringify({ posts: postsList, scheduledPosts });
    const dataUri = 'data:application/json;charset=utf-8,' + encodeURIComponent(dataStr);
    const exportFileDefaultName = 'multi_platform_posts.json';

    const linkElement = document.createElement('a');
    linkElement.setAttribute('href', dataUri);
    linkElement.setAttribute('download', exportFileDefaultName);
    linkElement.click();
  };

  const handleImportPosts = (event) => {
    const fileReader = new FileReader();
    fileReader.onload = (e) => {
      try {
        const importedData = JSON.parse(e.target.result);
        if (Array.isArray(importedData.posts)) {
          setPostsList(importedData.posts);
        }
        if (Array.isArray(importedData.scheduledPosts)) {
          setScheduledPosts(importedData.scheduledPosts);
        }
        setPostStatus('Posts imported successfully!');
      } catch (error) {
        setPostStatus('Error importing posts: ' + error.message);
      }
    };
    fileReader.readAsText(event.target.files[0]);
  };

  const handleEmojiClick = (emojiObject) => {
    setCurrentPost((prev) => prev + emojiObject.emoji);
  };

  return (
    <div className={`app-container ${isDarkMode ? 'dark-mode' : 'light-mode'}`}>
      <div className="nav-menu">
        <button onClick={handleWarpcastPost} className="nav-button">
          Post to Warpcast
        </button>
      </div>
      <div className="content">
        <div className="header">
          <div className="title-container">
            <h1 className="title">Mid Post</h1>
            <h2 className="subtitle">The mediocre and semi-automated Deso and Warpcast Post tool</h2>
          </div>
          <div className="header-controls">
            <button
              onClick={() => setIsDarkMode(!isDarkMode)}
              className="toggle-button"
            >
              <FontAwesomeIcon icon={isDarkMode ? faSun : faMoon} />{' '}
              {isDarkMode ? 'Light' : 'Dark'} Mode
            </button>
          </div>
        </div>

        <div className="login-section">
          {isLoggedIn ? (
            <p>
              Logged in as: {username || publicKey}{' '}
              <button onClick={handleLogout} className="icon-button">
                <FontAwesomeIcon icon={faSignOutAlt} /> Logout
              </button>
            </p>
          ) : (
            <button onClick={handleLogin} className="icon-button">
              <FontAwesomeIcon icon={faSignInAlt} /> Login to DeSo
            </button>
          )}
        </div>

        <div className="post-area">
          <textarea
            value={currentPost}
            onChange={(e) => setCurrentPost(e.target.value)}
            className="text-input"
            placeholder="Write your post here..."
          ></textarea>

          <div className="post-controls-wrapper">
            <div className="post-controls">
              <button
                onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                className="emoji-button"
              >
                <FontAwesomeIcon icon={faSmile} /> Emoji
              </button>
              {showEmojiPicker && (
                <div className="emoji-picker">
                  <EmojiPicker onEmojiClick={handleEmojiClick} />
                </div>
              )}
              <input
                type="file"
                accept=".json"
                onChange={handleImportPosts}
                style={{ display: 'none' }}
                id="import-input"
              />
              <label htmlFor="import-input" className="icon-button">
                <FontAwesomeIcon icon={faUpload} /> Import
              </label>
              <button onClick={handleExportPosts} className="icon-button">
                <FontAwesomeIcon icon={faDownload} /> Export
              </button>
              <button
                onClick={() => handleDesoPost()}
                disabled={isPosting || !isLoggedIn}
                className="post-button"
              >
                {isPosting ? 'Posting...' : 'Post to DeSo Now'}
              </button>
            </div>
            <div className="schedule-controls">
              <input
                type="date"
                value={scheduleDate}
                onChange={(e) => setScheduleDate(e.target.value)}
                className="date-input"
              />
              <input
                type="time"
                value={scheduleTime}
                onChange={(e) => setScheduleTime(e.target.value)}
                className="time-input"
              />
              <button
                onClick={handleSchedulePost}
                disabled={!isLoggedIn}
                className="schedule-button"
              >
                <FontAwesomeIcon icon={faCalendarAlt} /> Schedule Post
              </button>
            </div>
          </div>
        </div>

        {postStatus && <p className="post-status">{postStatus}</p>}

        <div className="posts-container">
          <div className="saved-posts">
            <h2>Saved Posts</h2>
            {postsList.map((post) => (
              <div key={post.id} className="post-item">
                <p>{post.content}</p>
              </div>
            ))}
          </div>

          <div className="scheduled-posts">
            <h2>Scheduled Posts</h2>
            {scheduledPosts.map((post) => (
              <div key={post.id} className="post-item">
                <p>{post.content}</p>
                <p>
                  Scheduled for: {new Date(post.scheduledTime).toLocaleString()}
                </p>
                <p>Status: {post.published ? 'Published' : 'Pending'}</p>
              </div>
            ))}
          </div>
        </div>

        <footer className="footer">
          &copy; 2024 Mid Post - shyguy, LLC
        </footer>
      </div>
    </div>
  );
};

export default DesoPostApp;