import React from 'react';
import DesoPostApp from './DesoPostApp';
import './DesoPostApp.css';

const App = () => {
  return (
    <div className="app">
      <DesoPostApp />
    </div>
  );
};

export default App;